import 'normalize.css'
import React from 'react'
import '@elvia/elvis/css/elvis.css'

import { Toast } from '@elvia/ui/src/components/Toast/Toast'
import { Providers } from './src/components/providers/Providers'

import FixedBottomContainer from './src/components/FixedBottomContainer'

import '@elvia/cookies/src/Banner/CookiePro.scss'
import '@elvia/ui/src/_globals.scss'
import './src/_theme.scss'
import './src/_global.scss'

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <FixedBottomContainer />
      <Toast />
      {element}
    </>
  )
}
